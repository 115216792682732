import { api, toQueryString } from "./api.ts";

let cachedSas: Promise<{url: string, sas: string, expires: number}> | null = null;

async function fetchAzSas() {
    let newSas = await api<{url: string, sas: string}>('GET', '/azure_sas');
    let parsed = new URLSearchParams(newSas.sas);
    console.log("Got SAS until ", new Date(parsed.get('se') || 0))
    return {
        ...newSas,
        expires: (new Date(parsed.get('se') || (Date.now() + 600_000))).getTime()
    };
}

async function getAzSas() {
    if (cachedSas) {
        let result = await cachedSas;
        if ((result.expires - 10_000) > Date.now()) return result;
    }
    return cachedSas = fetchAzSas();
}

type Params = {
    showOnly?: 'directories' | 'files',
    marker?: string,
    maxresults?: number
}
export interface AzureBlob {
    name:                  string;
    "Creation-Time":       string;
    "Last-Modified":       string;
    Etag:                  string;
    ResourceType:          string;
    "Content-Length":      string;
    "Content-Type":        string;
    "Content-Encoding":    string;
    "Content-Language":    string;
    "Content-CRC64":       string;
    "Content-MD5":         string;
    "Cache-Control":       string;
    "Content-Disposition": string;
    BlobType:              string;
    AccessTier:            string;
    AccessTierInferred:    string;
    LeaseStatus:           string;
    LeaseState:            string;
    ServerEncrypted:       string;
}


let parser = new DOMParser();
export async function readDir(under: string, params?: Params, signal?: AbortSignal) {
    let sas = await getAzSas();
    let url = sas.url + '?' + sas.sas + '&' + (toQueryString({
        restype: 'container',
        comp: 'list',
        prefix: under,
        delimiter: '/',
        maxresults: 20,
        ...params
    })).toString();
    let resp = await fetch(url, { signal });
    if (!resp.ok) 
        throw new Error(resp.status + ' ' + resp.statusText);
    
    let xml: XMLDocument = parser.parseFromString(await resp.text(), 'application/xml');
    let entries = Object.fromEntries([...xml.querySelectorAll('Blobs > *')].map(e => {
        let name = e.querySelector('Name')?.textContent || '';
        let properties = e.querySelector('Properties')?.childNodes;
        let props = { 
            name: name.replace(under, "").replace(/\/$/,""), 
            ...Object.fromEntries([...properties?.values()!].map(p => [p.nodeName, p.textContent])) 
        };
        return [name, props];
    })) as Record<string,AzureBlob>;
    return {
        entries,
        nextMarker: xml.querySelector('NextMarker')?.textContent || undefined
    };
    
}

export async function getDownloadUrl(blob: string) {
    let sas = await getAzSas();
    return sas.url + '/' + blob + '?' + sas.sas;
}