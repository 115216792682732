export const apiUrl = new URL(process.env.REACT_APP_API_URL || 'http://localhost:8000', window.location.href).toString().replace(/\/+$/, '');
type HttpMethods = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'OPTIONS' | 'HEAD';
export async function api<T = any>(method: HttpMethods, url: string, body?: FormData | any): Promise<T> {
    let headers: HeadersInit = {};
    if (body) {
        if (body instanceof FormData) {
            // nothing to do here - browser will automatically add correct content type
            // (and if we try to set it, browser won't set the boundary causing the request to fail)
        } else {
            headers['Content-Type'] = 'application/json';
            body = JSON.stringify(body);
        }
    }
    let resp = await fetch(apiUrl + url, {
        method: method,
        credentials: 'include',
        headers, body
    });
    if (!resp.ok) {
        if (resp.status === 401) {
            window.location.reload();
            await new Promise(() => {}); // wait forever
        }
        let text = await resp.text().catch(e => '');
        let error = new Error(resp.status + ' ' + resp.statusText + ": " + text, { cause: resp });
        try {
            let json = JSON.parse(text);
            if (json.detail) 
                error = new Error(json.detail, { cause: resp });
        } catch(e) {}
        throw error;
    }
    return await resp.json();
}
(window as any).api = api;
export function toQueryString(data: any) {
    return new URLSearchParams(data).toString();
}