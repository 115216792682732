import { ChangeEvent, useState } from "react";
import { Button, CloseButton, Form, Modal, Table } from "react-bootstrap";
import { api, toQueryString } from "../../utils/api.ts";
import { cleanFileName } from "../../utils/util.ts";
import { showToast } from "../components/Toasts.tsx";


export const UploadPolicies: React.FC<{ show: boolean, hide: () => void }> = ({ show, hide }) => {
    const [ busy, setBusy ] = useState(false);
    const [ files, setFiles ] = useState<File[]>([]);
    const [ customerName, setCustomerName ] = useState<string>("");


    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            let newFiles = Array.from(e.target.files!);
            setFiles((oldFiles) => [
                ...oldFiles.filter(file => !newFiles.find(f => f.name === file.name)),
                ...newFiles
            ])
        }
    };

    function removeFile(file: File) {
        setFiles(files => files.filter(f => f !== file));
    }

    const handleUpload = async (force: boolean = false) => {
        
        if (files.length === 0)
            return showToast({
                variant: "warning",
                title: "Upload Policies",
                text: "Please select a file to upload."
            });
        
        if (customerName.trim() === "")
            return showToast({
                variant: "warning",
                title: "Upload Policies",
                text: "Please enter a customer name for each file."
            });


        
        setBusy(true);
        try {
            const formData = new FormData();
            for (var file of [...files]) 
                formData.append('files', file, cleanFileName(file));
        
            const data = await api('POST', '/upload/?' + toQueryString({
                force, type: 'POLICY', customer_name: customerName
            }), formData);

            showToast({
                variant: "success",
                title: "Upload Policies",
                text: data.message
            })
            setFiles([]);
            setCustomerName("");
            hide();
        } catch (error) {
            showToast({
                variant: "danger",
                title: "Failed to upload file",
                text: (error as any)?.message
            });
            console.error("Failed to upload files:", error);
        }
        setBusy(false);
    };

    return <Modal show={show} onHide={hide}>
        <Modal.Header closeButton>
            <Modal.Title>Upload Policies</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form.Group controlId="formFile">
                <Form.Label>Customer name:</Form.Label>
                <Form.Control type="text" value={customerName} onChange={(e) => setCustomerName(e.target.value)} disabled={busy} />
            </Form.Group>
            <Table>
                {files.map((file) => <tr key={file.name}>
                    <td>{file.name}</td>
                    <td><CloseButton onClick={() => removeFile(file)} /></td>
                </tr>)}
            </Table>
            <Form.Group controlId="formFile">
                <Form.Label>Add Files:</Form.Label>
                <Form.Control type="file" onChange={handleFileChange} multiple disabled={busy} />
            </Form.Group>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="outline-secondary" onClick={hide} disabled={busy}>
                Cancel
            </Button>
            <Button variant="primary" onClick={() => handleUpload()} disabled={busy} >
                {busy ? "Uploading" : "Upload"}
            </Button>
        </Modal.Footer>
    </Modal>
}