import { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { AzureBlob, readDir } from "../../utils/azStorage.ts";
import { DownloadButton } from "./DownloadButton.tsx";
import { showToast } from "./Toasts.tsx";

export const CustomerRow: React.FC<{ path: string, folder: AzureBlob }> = ({ path, folder }) => {
    let [ expanded, setExpanded ] = useState(false);
    let [ busy , setBusy ] = useState(false);
    let [ children, setChildren ] = useState<[string, AzureBlob][]>([]);

    async function fetchPage() {
        try {
            setBusy(true);
            csignal.current?.abort();
            csignal.current = new AbortController();
            let results = await readDir(path, { 
                showOnly: 'files', 
                maxresults: 5000
            }, csignal.current.signal);
            setChildren((prev) => [...prev, ...Object.entries(results.entries)]);
        } catch (error) {
            console.error("Failed to read directory", {error});
            if ((error as any).name === "AbortError") return;
            showToast({
                title: "Expand '" + folder + "'",
                text: (error as any)?.message,
                variant: "danger"
            })
        } finally {
            setBusy(false)
        }
    }

    let csignal = useRef<AbortController>();
    useEffect(() => {
        setChildren([]);
        return () => csignal.current?.abort();
    }, [folder])

    const toggleExpanded = () => {
        if (!expanded) {
            setExpanded(true);
            if (children.length === 0) fetchPage();
        } else setExpanded(false);
    }

    return <>
        <tr>
            <td>{path.split("/")[0]}</td>
            <td>{folder.name}</td>
            <td>
                <Button size="sm" onClick={() => toggleExpanded()}>
                    {expanded ? 'Collapse' : 'Expand'}
                </Button>
            </td>
        </tr>
        {expanded && (
            <tr>
                <td colSpan={4}>
                    {children.length > 0 ? (
                        <div>
                            {children.map(([path,entry]) => (
                                <div key={path} className="d-flex justify-content-between align-items-center mb-2">
                                    <span className="text-truncate">{entry.name}</span>
                                    <div>
                                        <DownloadButton path={path} name={entry.name} /> {/*
                                        <Button
                                            size="sm"
                                            variant="danger"
                                            onClick={() => {}}
                                            className="ms-2"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                className="bi bi-trash"
                                                viewBox="0 0 24 24"
                                            >
                                                <path d="M3 6h18M6 6v14a2 2 0 002 2h8a2 2 0 002-2V6M8 6V4a2 2 0 012-2h4a2 2 0 012 2v2" />
                                            </svg>
                                        </Button> */}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        busy ? 
                            <div className="spinner-border spinner-border-lg" role="status" /> :
                            <p>No files available.</p>
                    )}
                </td>
            </tr>
        )}
    </>
}