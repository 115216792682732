import React from 'react';

const LoggedOut: React.FC = () => {
  return (
    <div className="logged-out">
      <h2>You have been logged out</h2>
      <p>Thank you for using our service. You can close this window now.</p>
    </div>
  );
};

export default LoggedOut;
