import { useComputed } from "@preact/signals-react";
import { useRef } from "react";
import { Button } from "react-bootstrap";
import { fileIsBeingDownloaded, queueDownload } from "../../utils/download.ts";

export const DownloadButton = ({ path, name }: { path: string, name: string }) => {
    let isDownloading = useRef(fileIsBeingDownloaded(path));

    function doDownload() {
        queueDownload(path, async (resp: Response) => {
            const blob = await resp.blob();
            const dlUrl = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = dlUrl;
            a.download = name;
            a.click();
            URL.revokeObjectURL(dlUrl);
            a.remove();
        })
    }

    return useComputed(() => {
        let download = isDownloading.current.value;
        if (download)
            return <Button variant="primary" disabled>
                {download.state.value === 'downloading' && download.size.value > 0 ? 
                    <progress value={download.progress.value} max={download.size.value} /> :
                    "Downloading..."
                }
            </Button>;
        else
            return <Button variant="primary" onClick={doDownload}>Download</Button>;
    })
}