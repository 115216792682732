import React, { useEffect, useState } from 'react';
import '../styles/App.css';
import { api, apiUrl } from '../utils/api.ts';
import Logo from './components/Logo.tsx'; // Ensure Logo component is imported
import FileExplorer from './pages/FileExplorer.tsx';
import LoggedOut from './pages/LoggedOut.tsx';

const App: React.FC = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const [username, setUsername] = useState('');
  const [loggedOut, setLoggedOut] = useState(false); // New state to track if the user has logged out

  useEffect(() => {
    if (!loggedOut) {  // Only check auth status if the user hasn't logged out
      api('GET', '/auth/status').then((status) => {
        if (status.authenticated) {
          setAuthenticated(true);
          setUsername(status.username);
        } else {
          throw new Error('Not authenticated');
        }
      }).catch((e) => {
        console.error(e)
        window.location.href = apiUrl + '/auth/login';
      })
    }
  }, [loggedOut]);

  const handleLogout = () => {
    api('DELETE','/auth/logout').then(() => {
      setAuthenticated(false);
      setLoggedOut(true);  // Set the logged-out state
    });
  };

  if (loggedOut) {
    return <LoggedOut />;  // Show the logged-out confirmation page
  }

  if (!authenticated) {
    return <div>Loading...</div>;
  }

  return (
    <div className="App">
      <header className="App-header">
        <div className="header-content">
          <div className="header-left">
            <div className="logo-container">
              <Logo />
            </div>
            <div className="header-text">
              <h3>AI Processing</h3>
              <p>Welcome, {username}</p>
            </div>
          </div>
          <div className="header-right">
            <button onClick={handleLogout} className="logout-button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-log-out"
                viewBox="0 0 24 24"
              >
                <path d="M9 21H3a2 2 0 01-2-2V5a2 2 0 012-2h6"></path>
                <polyline points="16 17 21 12 16 7"></polyline>
                <line x1="21" y1="12" x2="9" y2="12"></line>
              </svg>
              Logout
            </button>
          </div>
        </div>
      </header>
      <main className="App-main">
        <FileExplorer />
      </main>
      <footer className="App-footer">
        <p>Powered by EfficientEther Ltd 2024 © All rights reserved.</p>
      </footer>
    </div>
  );
};

export default App;
