import { useCallback, useEffect, useRef, useState } from "react";

const rtf = new Intl.RelativeTimeFormat({ numeric: 'auto' });
export const SecondsSince = ({ time, source = Date.now }: { time: number; source?: () => number; }) => {
  let [seconds, setSeconds] = useState(0);
  let interval = useRef<NodeJS.Timeout>();
  const update = useCallback(() => {
    let diff = time - source();
    setSeconds(Math.floor(diff / 1000));
    let delay = 1000 - (diff % 1000);
    if (delay === 1000) delay = 0;
    interval.current = setTimeout(update, delay);
  }, [time, source]);
  useEffect(() => {
    update();
    return () => clearTimeout(interval.current);
  }, [source, update, time]);
  let abs = Math.abs(seconds);
  if (abs < 1) return "just now";

  return rtf.format(seconds, 'second');
};
