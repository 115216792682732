
import { computed, Signal, signal, untracked } from "@preact/signals-react";
import { getDownloadUrl } from "./azStorage.ts";

interface DownloadState<T = any> {
    url: string;
    state: Signal<'pending' | 'downloading' | 'complete' | 'failed'>;
    size: Signal<number>;
    handler: (resp: Response) => Promise<T>;
    progress: Signal<number>;
    result?: T | Error;
    await: Promise<T>;
}

let downloadQueue = signal<DownloadState[]>([]);
let currentDownload = signal<DownloadState | null>(null);
(window as any).downloadDebug = {downloadQueue, currentDownload};
let downloadBusy = false;

async function downloadNext() {
    while (downloadQueue.peek().length && !downloadBusy) {
        let queue = [...downloadQueue.peek()];
        let next = queue.shift();
        downloadQueue.value = queue;
        currentDownload.value = next || null;
        if (!next) return;
        try {
            downloadBusy = true;
            next.state.value = 'downloading';
            
            let response = await fetch(await getDownloadUrl(next.url));
            if (!response.ok) throw new Error(response.status + ' ' + response.statusText);
            let size = parseInt(response.headers.get('Content-Length') || '0');
            next.size.value = size;

            const res = new Response(new ReadableStream({
                async start(controller) {
                    const reader = response.body!.getReader();
                    for (;;) {
                        const { done, value } = await reader.read();
                        if (done) break;
                        next.progress.value += value.byteLength;
                        controller.enqueue(value);
                    }

                    controller.close();
                }
            }));
            next.result = await next.handler(res);
            next.state.value = 'complete';
        } catch(e) {
            next.result = e;
            next.state.value = 'failed';
        } finally {
            currentDownload.value = null;
            downloadBusy = false;
        }
    }
}

export function queueDownload<T>(url: string, handler: (resp: Response) => T): DownloadState<T> {
    let state = {
        url,
        state: signal('pending'),
        size: signal(0),
        progress: signal(0),
        handler,
        get await() {
            if (!state._await) {
                state._await = new Promise((a,r) => {
                    let unsub = state.state.subscribe(s => {
                        if (s !== 'complete' && s !== 'failed') return;
                        unsub();
                        (s === 'complete' ? a : r)(state.result as T);
                    });
                })
            }
            return state._await;
        },
        _await: null
    } as DownloadState & { _await: null | Promise<T> };
    downloadQueue.value = [...downloadQueue.peek(), state];
    untracked(downloadNext);
    
    return state;
}
export function fileIsBeingDownloaded(url: string) {
    return computed(() => currentDownload.value?.url === url ? currentDownload.value! : downloadQueue.value.find(d => d.url === url));
}