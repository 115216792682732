import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState } from 'react';
import { Button, ButtonGroup, Nav } from 'react-bootstrap';
import { FolderView } from '../components/FolderView.tsx';
import { UploadDISA } from '../modals/uploadDISA.tsx';
import { UploadPolicies } from '../modals/uploadPolicies.tsx';


const FileExplorer: React.FC = () => {
  const [showUploadDisa, setShowUploadDisa] = useState<boolean>(false);
  const [showUploadPolicy, setShowUploadPolicy] = useState<boolean>(false);
  const [tab, setTab] = useState<string | null>(null);



  return (<>
    <div className="container mt-4" style={{ fontSize: '14px' }}>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h4>File Explorer</h4>
        <ButtonGroup>
          <Button variant="primary" onClick={() => setShowUploadDisa(true)}>
            Upload DISA
          </Button>
          <Button variant="primary" onClick={() => setShowUploadPolicy(true)}>
            Upload Policies
          </Button>
        </ButtonGroup>
      </div>
      <Nav variant="tabs" onSelect={(selectedKey) => setTab(selectedKey)}>
        <Nav.Item>
          <Nav.Link eventKey="DISA/">DISA</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="POLICY/">Policy</Nav.Link>
        </Nav.Item>
      </Nav>
      {tab ? <FolderView folder={tab} /> : <div>Select a tab to view files</div>}
      


      {showUploadDisa && 
        <UploadDISA show={showUploadDisa} hide={() => setShowUploadDisa(false)} /> }
      {showUploadPolicy && 
        <UploadPolicies show={showUploadPolicy} hide={() => setShowUploadPolicy(false)} /> }

    </div>

  </>);
};


export default FileExplorer;
