import { computed } from "@preact/signals-react";
import { useEffect, useRef, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { getJobs, jobComplete, runningJobs } from "../../progress.ts";
import { AzureBlob, readDir } from "../../utils/azStorage.ts";
import { CustomerRow } from "./CustomerRow.tsx";
import { showToast } from "./Toasts.tsx";

export const FolderView = ({ folder } : { folder: string }) => {
    let [ busy , setBusy ] = useState(true);
    let [ children, setChildren ] = useState<[string, AzureBlob][]>([]);
    let [ marker, setMarker ] = useState<string | null>(null);

    async function fetchPage() {
        try {
            csignal.current?.abort();
            csignal.current = new AbortController();
            let results = await readDir(folder, { 
                showOnly: 'directories', 
                maxresults: 10,
                ...(marker ? { marker } : {}) 
            }, csignal.current.signal);
            setMarker(results.nextMarker || null);
            setChildren((prev) => [...prev, ...Object.entries(results.entries)]);
        } catch (error) {
            console.error("Failed to read directory", {error});
            if ((error as any).name === "AbortError") return;
            showToast({
                title: "View '" + folder + "'",
                text: (error as any)?.message,
                variant: "danger"
            })
        } finally {
            setBusy(false)
        }
    }

    let csignal = useRef<AbortController>();
    function refresh() {
        setChildren([]);
        children = [];
        setMarker(null);
        marker = null;
        fetchPage();
    }
    useEffect(() => {
        refresh();
        
        return () => csignal.current?.abort();
    }, [folder])

    useEffect(() => {
        let el = (e: Event) => {
            if ((e as CustomEvent).detail.startsWith(folder)) {
                refresh();
            };
        }
        jobComplete.addEventListener('complete', el);
        return () => jobComplete.removeEventListener('complete', el);
    })
    
    getJobs();
    let running = computed(() => 
        [...runningJobs.value.values()].filter(job => job.status !== "completed"))
    return <>
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Type</th>
                    <th>Customer</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {running.value.filter(j => j.name.startsWith(folder)).map(job =><tr key={job.name}>
                    <td>{job.name.split("/")[0]}</td>
                    <td>{job.name.split("/")[1]}</td>
                    <td>{
                        job.status === "processing" ? "Processing" : 
                        job.status === "uploading" ? "Finishing" : 
                        job.status === "queued" ? "Queued" :
                        job.status
                    }</td>
                </tr>)}
                {children.map(([path,entry]) => <CustomerRow key={path} path={path} folder={entry} />)}
                { 
                    busy ? <tr><td colSpan={3}>Loading...</td></tr> :
                    marker ? <tr><td colSpan={3}><Button variant="link" onClick={fetchPage}>Load more...</Button></td></tr> : 
                    null  
                }
            </tbody>
        </Table>
    </>
}