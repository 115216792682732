import { signal } from "@preact/signals-react";
import { useSignals } from "@preact/signals-react/runtime";
import { Toast as BSToast, ToastContainer } from "react-bootstrap";
import { Variant } from "react-bootstrap/esm/types";
import { SecondsSince } from "../../utils/i18n.ts";


type Toast = {text: string, title?: string, variant?: Variant};
type Toasts = Map<number, Toast>;
const toasts = signal<Toasts>(new Map());

function updateToasts(handler: (toasts: Toasts) => void) {
    let newMap = new Map(toasts.peek());
    handler(newMap);
    toasts.value = newMap;
}

export function showToast(message: string | Toast) {
    updateToasts(toasts => 
        toasts.set(performance.now(), typeof message == "string" ? { text: message } : message)
    );
}
export const ToastsDisplay: React.FC = () => {
    useSignals();
    return <ToastContainer position='top-end' className='p-2'>
        {[...toasts.value.entries()].sort(([a],[b])=>b-a).map(([id, toast]) => {
            let icon = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-info"><circle cx="12" cy="12" r="10"/><path d="M12 16v-4"/><path d="M12 8h.01"/></svg>
            if (toast.variant === "danger") 
                icon = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-octagon-x"><path d="m15 9-6 6"/><path d="M2.586 16.726A2 2 0 0 1 2 15.312V8.688a2 2 0 0 1 .586-1.414l4.688-4.688A2 2 0 0 1 8.688 2h6.624a2 2 0 0 1 1.414.586l4.688 4.688A2 2 0 0 1 22 8.688v6.624a2 2 0 0 1-.586 1.414l-4.688 4.688a2 2 0 0 1-1.414.586H8.688a2 2 0 0 1-1.414-.586z"/><path d="m9 9 6 6"/></svg>
            else if (toast.variant === "warning")
                icon = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-circle-alert"><circle cx="12" cy="12" r="10"/><line x1="12" x2="12" y1="8" y2="12"/><line x1="12" x2="12.01" y1="16" y2="16"/></svg>
            else if (toast.variant === "success")
                icon = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-circle-check-big"><path d="M21.801 10A10 10 0 1 1 17 3.335"/><path d="m9 11 3 3L22 4"/></svg>;

            return (
                <BSToast 
                    key={id}
                    bg={toast.variant} 
                    className='mb-2'
                    show={true} delay={5000} autohide animation={true}
                    onClose={() => updateToasts(toasts => toasts.delete(id))} 
                >
                    <BSToast.Header>
                        {icon}
                        <strong className="me-auto ms-2 ">{toast.title || "Message"}</strong>
                        <small><SecondsSince time={id} source={() => performance.now()}/></small>
                    </BSToast.Header>
                    <BSToast.Body>{toast.text}</BSToast.Body>
                </BSToast>
            );
        })}
    </ToastContainer>
}